import React from "react";
import tw from "twin.macro";
import MainFeature1 from "components/features/TwoColWithButton.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import Footer from "components/footers/MiniCenteredFooter.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const CenterDescription = tw.span`text-justify`;

export default () => {
  return (
    <div>
      <MainFeature1
        subheading={<Subheading>About Atrika LLC.</Subheading>}
        heading="Boutique software development agency, specializing in Gen AI."
        description="We are skilled at building next generation products. We specialize in the end to end Gen AI stack, and are currently working on a few projects in the space."
        buttonRounded={false}
        primaryButtonText=""
        imageSrc=""
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Rapid yet thoughtful innovation."
        description="Deliver excellent results in creative projects that have deep and broad innovation scope."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
    </div>
  );
};
