import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import HeaderBase from "components/headers/light.js";
import { NavLinks, NavLink } from "components/headers/light.js";
import { Outlet } from "react-router-dom";
import Footer from "components/footers/MiniCenteredFooter.js";
const Header = tw(HeaderBase)`max-w-none`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">About</NavLink>
      {/* <NavLink href="/#">Blog</NavLink> */}
      <NavLink href="/contact-us">Contact Us</NavLink>
      {/* <NavLink href="/#">Testimonials</NavLink> */}
    </NavLinks>
  ];

  return (
    <AnimationRevealPage>
      <Header links={navLinks} />
      <Outlet />
      <Footer /> 
    </AnimationRevealPage>
  );
}
