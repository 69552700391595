import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import FAQ from "components/faqs/SingleCol.js";
import tw from "twin.macro";

const HighlightedText = tw.span`text-primary-500`
export default () => {
    return (
        <div>
        <Hero />
        <FeatureStats/>
        <Features 
          heading={<><HighlightedText>Features</HighlightedText></>}
        />
        {
          /* <MainFeature
            heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
          /> 
          <Testimonial 
            heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
          />
          {/* <Pricing 
            heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
          /> */}
          <FAQ
            heading={<><HighlightedText>Questions ?</HighlightedText></>}
          />
          {/* <Blog
            subheading="Blog"
            heading={<>We love <HighlightedText>Writing</HighlightedText></>}
          />
          <GetStarted/>
          /* <Footer /> */
        }
        </div>
    );
}