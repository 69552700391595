import React from "react";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";


export default () => {
  return (
    <div>
      <ContactUsForm description="We will get back to you within 24 hrs."/>
      <ContactDetails
        subheading=""
        heading="Our Location"
        description="We are located in Silicon Valley, California. Our physical offices are in Los Gatos, CA."
        mainEmail="engage@atrika.us"
        cards={[  ]}
      />
    </div>
  );
};
